import React, { useState } from 'react';
import axios from 'axios';
import './ConfirmAudioSettings.css';

const ConfirmAudioSettings = ({
  prevStep,
  handleNextStep,
  handleChange,
  values,
  mantras,
  storeAudioUrl,
  selectedLanguage,
  languageName,
  gender
}) => {

    
    const [isPlaying, setIsPlaying] = useState({
        Male: false, 
        Female: false,
        music1: false,
        music2: false,
        music3: false,
        music4: false
      });
  const [selectedMusic, setSelectedMusic] = useState('music1');

  const selectMusic = (musicChoice) => {
    setSelectedMusic(musicChoice);
    handleChange('music')(musicChoice);
  };


  const playAudio = (gender) => { 
    // Pause any currently playing audio
    Object.keys(isPlaying).forEach(g => {
      if (isPlaying[g]) {
        const audio = document.getElementById(`audio-preview-${g}`);
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(prev => ({ ...prev, [g]: false }));
      }
    });
  
    // Play the selected audio
    const audio = document.getElementById(`audio-preview-${gender}`);
    if (!isPlaying[gender]) {
      audio.play();
      setIsPlaying(prev => ({ ...prev, [gender]: true }));
    }
  };

  const playMusic = (musicSelection) => {
    // Pause any currently playing audio (both voice and music)
    Object.keys(isPlaying).forEach(key => {
      if (isPlaying[key]) {
        const audio = document.getElementById(`audio-preview-${key}`);
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(prev => ({ ...prev, [key]: false }));
      }
    });

    // Play the selected music
    const audio = document.getElementById(`audio-preview-${musicSelection}`);
    if (!isPlaying[musicSelection]) {
      audio.play();
      setIsPlaying(prev => ({ ...prev, [musicSelection]: true }));
    }
  };

  const getMusicSrc = (musicName) => {
    return `https://mymantramaker.s3.amazonaws.com/preview-${musicName}.mp3`;
  };
  

  
  const HandleVoiceAPI = async () => {
    const audioUrl = await generateAudio(mantras, selectedMusic);
    const processedAudioUrl = await processAudio(audioUrl, selectedMusic); // Pass the URL and music selection
    storeAudioUrl(processedAudioUrl); // Make sure to store the final URL after processing
    return processedAudioUrl;
  };

  const generateAudio = async (text, music) => { // Receive music as an argument
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/generate-audio`, {
        text: text,
        language: selectedLanguage,
        languageName: languageName,
        gender: gender,
        music: music // Include music in the payload
      });
      //console.log(response.data.audioUrl);
      return response.data.audioUrl;
    } catch (error) {
      console.error('Error generating audio:', error);
      throw error;
    }
  };

  const processAudio = async (audioUrl, musicSelection) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/process-audio`, {
        audioUrl: audioUrl,
        musicSelection: musicSelection,
      });
  
      const newAudioUrl = response.data.newAudioUrl;
      //console.log(newAudioUrl);
      return newAudioUrl;
    } catch (error) {
      console.error('Error processing audio:', error);
      throw error;
    }
  };

  
  const getAudioSrc = (gender) => {
    const languageCode = selectedLanguage === 'EN' ? 'en' : selectedLanguage === 'SPN' ? 'spn' : 'fr';
    const genderCode = gender === 'Male' ? 'male' : 'female';
    return `https://mymantramaker.s3.amazonaws.com/preview-${genderCode}${languageCode}.mp3`;
  };

  return (
    
    <div className="form-container">
    <div className="audio-selection">
      <h2>
        {selectedLanguage === 'EN' ? 'Select Voice' : selectedLanguage === 'SPN' ? 'Seleccionar voz' : 'Sélectionnez la voix'}
      </h2>
      <div className="gender-selection">
        {/* Male Selection */}
        <label
          className={values.voice === 'Male' ? 'selected' : ''}
          onClick={() => handleChange('voice')('Male')}
        >
          <div className="voice-preview">
            <img src='/images/male.png' alt="Male" className='gender-icon'/>
            <img 
              src='/images/speakericon.png' 
              alt="Play Preview" 
              className="speaker-icon"
              onClick={(e) => { e.stopPropagation(); playAudio('Male'); }}
            />
          </div>
          <div>
            {selectedLanguage === 'EN' ? 'Matthew' : selectedLanguage === 'SPN' ? 'Pedro' : 'Liam'}
          </div>
          <audio id="audio-preview-Male" src={getAudioSrc('Male')}></audio>
        </label>
        {/* Female Selection */}
        <label
          className={values.voice === 'Female' ? 'selected' : ''}
          onClick={() => handleChange('voice')('Female')}
        >
          <div className="voice-preview">
            <img src='/images/female.png' alt="Female" className='gender-icon'/>
            <img 
              src='/images/speakericon.png' 
              alt="Play Preview" 
              className="speaker-icon"
              onClick={(e) => { e.stopPropagation(); playAudio('Female'); }}
            />
          </div>
          <div>
            {selectedLanguage === 'EN' ? 'Ruth' : selectedLanguage === 'SPN' ? 'Lupe' : 'Gabrielle'}
          </div>
          <audio id="audio-preview-Female" src={getAudioSrc('Female')}></audio>
        </label>
      </div>
      <h2>
        {selectedLanguage === 'EN' ? 'Select Music' : selectedLanguage === 'SPN' ? 'Seleccionar música' : 'Sélectionnez la musique'}
      </h2>
      <div className="music-selection">
        {['music1', 'music2', 'music3', 'music4'].map((music, idx) => (
          <div key={idx} className="music-choice-container">
            <label
              className={`music-choice ${selectedMusic === music ? 'selected' : ''}`}
              onClick={() => selectMusic(music)}
              >
                <img
                  src={`/images/${music}.png`}
                  alt={`Music ${idx + 1}`}
                  className='music-icon'
                />
                <div className="music-label">{music}</div>
              </label>
              <img 
                src='/images/speakericon.png' 
                alt="Play Preview" 
                className="speaker-icon"
                onClick={(e) => {
                  e.stopPropagation(); 
                  playMusic(music);
                }}
              />
              <audio id={`audio-preview-${music}`} src={getMusicSrc(music)}></audio>
          </div>
        ))}
      </div>


      <div className='bottom-buttons'>
        <button onClick={prevStep}>
          {selectedLanguage === 'EN' ? 'Previous' : selectedLanguage === 'SPN' ? 'Anterior' : 'Précédent'}
        </button>
      
        <button onClick={() => handleNextStep(HandleVoiceAPI)}>
        {selectedLanguage === 'EN' ? 'Submit' : selectedLanguage === 'SPN' ? 'Enviar' : 'Soumettre'}
        </button>
      
      </div>
    </div>
    </div>
    
  );
};

export default ConfirmAudioSettings;
