import React, { useState } from 'react';
import './LanguageSelector.css';

const LanguageSelector = ({ selectedLanguage, onLanguageChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const languageString = {
    EN: "English",
    FR: 'French',
    SPN: 'Spanish'
  };

  // Get the other two languages that are not currently selected
  const otherLanguages = Object.keys(languageString).filter(language => language !== selectedLanguage);

  return (
    <div id="div1" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
      <div id="diva1">
        <img id="img1" src={`/images/${languageString[selectedLanguage].toLowerCase()}.png`} alt={selectedLanguage} />
        {isDropdownOpen && (
          <div id="divaa1">
            {otherLanguages.map(language => (
              <div key={language} onClick={() => { onLanguageChange({ target: { value: language } }); setIsDropdownOpen(false); }}>
                <img src={`/images/${languageString[language].toLowerCase()}.png`} alt={languageString[language]} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
