import React, { useEffect } from 'react';
import Sparkles from './Sparkles';
import './DynamicBackground.css';
import ShootingStars from './components/ShootingStars';

const DynamicBackground = ({ showElements, darkMode, loading }) => {
  
  const randomNumber = Math.floor(Math.random() * 4) + 1;
  const characterImagePath = darkMode ? `/images/splashman${randomNumber}.png` : `/images/splashgirl${randomNumber}.png`;
  const logoImagePath = darkMode ? '/images/logodark.png' : '/images/logo.png';
  const backgroundImagePath = darkMode ? '/images/splashbgdark.png' : '/images/splashbg.png';

  useEffect(() => {
    // Any animation or initialization logic can go here
  }, []);

  const characterImage = (
    <img src={characterImagePath} alt="Character" className="character" />
  );

  return (
    <div className="background-container" style={{ backgroundImage: `url(${backgroundImagePath})` }}>
      <img src={logoImagePath} alt="Logo" className="logo" style={{ visibility: showElements ? 'visible' : 'hidden' }} />
      <div className="character-container">
        {darkMode ? characterImage : <Sparkles>{characterImage}</Sparkles>}
        <p className="start-text">
  {showElements ? 'click screen to start' : 'Loading...' }
</p>
      </div>
      {darkMode && <ShootingStars />}
      {!darkMode && (
        <>
          <img src="/images/clouds1.png" alt="Fluffy Cloud" className="cloud cloud1" />
          <img src="/images/clouds1.png" alt="Fluffy Cloud Duplicate" className="cloud cloud1 duplicate" />
          <img src="/images/clouds2.png" alt="Wispy Cloud" className="cloud cloud2" />
          <img src="/images/clouds2.png" alt="Wispy Cloud Duplicate" className="cloud cloud2 duplicate" />
          <img src="/images/clouds3.png" alt="Dense Cloud" className="cloud cloud3" />
          <img src="/images/clouds3.png" alt="Dense Cloud Duplicate" className="cloud cloud3 duplicate" />
        </>
      )}
    </div>
  );
}

export default DynamicBackground;
