import React, { useState, useEffect, useRef } from 'react';

const AudioPlayer = ({ audioSrc, title, imageSrc, themesText }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRepeating, setIsRepeating] = useState(false);
  const audioRef = useRef(null); // Changed to useRef(null)

  useEffect(() => {
    // This effect will set up event listeners for the audio element
    const audio = audioRef.current;

    // Set the duration when the metadata is loaded
    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    // Update the current time as the audio plays
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      if (isRepeating) {
        audio.currentTime = 0; // Reset the time
        audio.play(); // Play again
      }
    };
    // Attach event listeners
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded); // Listen for when the audio ends


    // Play the audio if isPlaying is true
    if (isPlaying) {
      audio.play();
    }

    // Detach event listeners on cleanup
    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded); // Remove the event listener for when the audio ends

    };
  }, [isPlaying, isRepeating]); // Only re-run the effect if isPlaying changes

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const toggleRepeat = () => {
    setIsRepeating(!isRepeating);
  };
  useEffect(() => {
    // This effect toggles the play and pause based on isPlaying
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlaying]); // Only re-run the effect if isPlaying changes

  return (
    <div className="audio-player">
      <div className="track-info">
        <img className="cover-art" src={imageSrc} alt={`Cover art for ${title}`} />
        <h2 className="title">{title}</h2>
        <p className="title-description">{themesText}</p>
        <div className="controls">
          <button onClick={toggleRepeat}>
            {isRepeating ? <img src="./images/repeat.png" alt=""/> : <img src="./images/repeat2.png" alt=""/>} {/* Repeat button with toggle effect */}
          </button>
          <button onClick={togglePlayPause}>
            {isPlaying ? <img src="./images/pause.png" alt=""/> : <img src="./images/play.png" alt=""/>}
          </button>
          <a
            href={audioSrc}
            download={title.replace(/\s+/g, '_') + '.mp3'}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <button>
            <img src="./images/download.png" alt=""/>
            </button>
          </a>
        </div>
        <div className="progress-bar">
          <input
            type="range"
            value={currentTime}
            step="1"
            min="0"
            max={duration}
            onChange={(e) => (audioRef.current.currentTime = e.target.value)}
          />
          <div className="time">
            <div>{Math.floor(currentTime / 60)}:{String(Math.floor(currentTime % 60)).padStart(2, '0')}</div>
            <div>{Math.floor(duration / 60)}:{String(Math.floor(duration % 60)).padStart(2, '0')}</div>
          </div>
        </div>
      </div>
      {/* The audio element is hidden and used for its functionality */}
      <audio
        ref={audioRef}
        src={audioSrc}
        hidden // Hide the default HTML5 audio controls
      />
    </div>
  );
};

export default AudioPlayer;
