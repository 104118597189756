import React from 'react';
import LanguageSelector from './LanguageSelector';  // Import the LanguageSelector component
import './LanguageSelector.css';
import axios from 'axios';

const MantraTheme = ({ handleChange, values, setLoading, handleNextStep, selectedLanguage, onLanguageChange, inputFields, setInputFields, handleInputChange}) => {
  // const [inputFields, setInputFields] = useState([{ value: values.theme1 || '' }]);

  // const handleInputChange = (index, event) => {
  //   const newInputFields = [...inputFields];
  //   newInputFields[index].value = event.target.value;
  //   setInputFields(newInputFields);
  //   handleChange(`theme${index + 1}`)(event);
  // };



  const handleAddField = () => {
    if (inputFields.length < 5) {
      setInputFields([...inputFields, { value: '' }]);
    }
  };

  const languageCodeMap = {
    EN: 'en-US',
    FR: 'fr-CA',
    SPN: 'es-US',
  };

  const languageString = {
    EN: "English",
    FR: 'French',
    SPN: 'Spanish',
  };

  const doMantraThemeStep = async () => {
    setLoading(true);
    const languageCode = languageCodeMap[selectedLanguage];
    const languageText = languageString[selectedLanguage];
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/mantra`, {
            themes: inputFields.map(field => field.value),
            language: languageCode,
            languageText: languageText,
        });

        if (response.data.success) {
           // console.log("Received AI Mantra:", response.data.AIMantra);
            // Transform the received mantra text like how it's done in ConfirmText component
            const transformedMantraText = response.data.AIMantra.split(/\d+\./).filter(Boolean).map(mantra => `<p>${mantra.trim()}</p>`).join('');
            handleChange('mantrasText')(transformedMantraText);
        } else {
            console.error(response.data.message);
        }
    } catch (error) {
        console.error('There was an error sending the data to the server:', error);
    } finally {
        setLoading(false);
    }
};


  const handleRemoveField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  return (
    <div className="form-container theme-select">
      <LanguageSelector selectedLanguage={selectedLanguage} onLanguageChange={onLanguageChange} />
      <h2>{selectedLanguage === 'EN' ? 'What do you want your affirmations to be about?' :
 selectedLanguage === 'FR' ? 'Sur quoi voulez-vous que vos affirmations portent ?' : '¿Sobre qué quieres que sean tus afirmaciones?'}
</h2>
      <p>{selectedLanguage === 'EN' ? 'Type up to 5 topics' : selectedLanguage === 'SPN' ? 'Escribe hasta 5 temas' : "Entrez jusqu'à 5 thèmes"}</p>
      {inputFields.map((inputField, index) => (
      <div className="input-group" key={`theme-${index}`}>
        <input
          type="text"
          value={inputField.value}
          placeholder={selectedLanguage === 'EN' ? 'Theme' : selectedLanguage === 'SPN' ? 'Temas' : "Thème"}
          onChange={(event) => handleInputChange(index, event)} // Pass the event correctly
        />
          <div className="button-container">
            <button className="minus-button" onClick={() => handleRemoveField(index)} disabled={inputFields.length === 1}>-</button>
          </div>
        </div>
      ))}
      <button className="plus-button" onClick={handleAddField} disabled={inputFields.length === 5}>+</button>
      <br />
      <button className='next-button' onClick={() => handleNextStep(doMantraThemeStep)}>{selectedLanguage === 'EN' ? 'Next Step' : selectedLanguage === 'SPN' ? 'Próximo Paso' : 'Étape Suivante'}</button>
    </div>
  )
}

export default MantraTheme;
