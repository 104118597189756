import React from 'react';
import AudioPlayer from './AudioPlayer';



const FinalResult = ({ resetForm, newAudioUrl, selectedLanguage, inputFields}) => {
  
  const themesText = inputFields.map(field => field.value).join(', ')

  return (
    <div className="form-container">
      <h2 className='final-title'>
      {
  selectedLanguage === 'EN' ? 'Your Affirmations' :
  selectedLanguage === 'SPN' ? 'Tus Afirmaciones' :
  'Vos Affirmations'
}      </h2>
      {newAudioUrl && (
        <div className="player-container">
          
          <AudioPlayer
  audioSrc={newAudioUrl}
  title={"MyAffirmations.net"}
  imageSrc={'/images/audioimage.png'}
  themesText={themesText}
/>
          
        </div>
      )}
       

      <button onClick={resetForm}>
        {selectedLanguage === 'EN' ? 'Start Over' : selectedLanguage === 'SPN' ? 'Empezar de nuevo' : 'Recommencer'}
      </button>
    </div>
  );
}

export default FinalResult;
