import React from 'react';

// Inline styles for the component
const styles = {
    backgroundContainer: {
      position: 'relative', // Establishes a new positioning context
      width: '100%',        // Container width
      height: '100%',       // Container height
      overflow: 'hidden',   // Prevents child elements from overflowing
      zIndex: 0,            // Sets the stacking order for the container
    },
    starryBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      backgroundRepeat: 'repeat',
      backgroundPosition: 'top center',
      zIndex: 0,
    },
    stars: {
      position: 'absolute',
      backgroundImage: 'url(http://www.script-tutorials.com/demos/360/images/stars.png)',
      zIndex: 1,
      width: '100%',
      height: '45%',
    },

//     clouds: {
//       position: 'absolute',
//       backgroundImage: 'url(http://www.script-tutorials.com/demos/360/images/clouds3.png)',
//       zIndex: 2,
//       width: '100%',
//       height: '100%',
//       animation: 'move-clouds-back 200s linear infinite',
//       opacity: 0.75,
//       pointerEvent:'none'
      
//     }
 };
  
  // Usage in your React component
  <div style={styles.backgroundContainer}>
    <div style={styles.starryBackground} />
    <div style={styles.stars} />
    <div style={styles.twinkling} />
    <div style={styles.clouds} />
  </div>

// Keyframes for the animations
const keyframes = `
@keyframes move-twink-back {
    from {background-position:10000px 5000px;}
    to {background-position:0 0;}
  }
  @-webkit-keyframes move-twink-back {
    from {background-position:10000px 5000px;}
    to {background-position:0 0;}
  }
  @-moz-keyframes move-twink-back {
    from {background-position:10000px 5000px;}
    to {background-position:0 0;}
  }
  @-ms-keyframes move-twink-back {
    from {background-position:10000px 5000px;}
    to {background-position:0 0;}
  }
  
  @keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:-10000px 0;}
  }
  @-webkit-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:-10000px 0;}
  }
  @-moz-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:-10000px 0;}
  }
  @-ms-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:-10000px 0;}
  };`

// StarrySky component definition
class StarrySky extends React.Component {
  render() {
    return (
      <div>
        <style>{keyframes}</style>
        <div style={{ ...styles.starryBackground, ...styles.stars }} />
        <div style={{ ...styles.starryBackground, ...styles.twinkling }} />
        <div style={{ ...styles.starryBackground, ...styles.clouds }} />
      </div>
    );
  }
}

export default StarrySky;
