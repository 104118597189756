import React, { useState } from 'react';
import MultiStepForm from './components/MultiStepForm';
import DynamicBackground from './DynamicBackground';
import Header from './Header';
import StarrySky from './components/StarrySky';

function App() {
  const [showElements, setShowElements] = useState(true);
  const [darkMode, setDarkMode] = useState(false);


  const handleScreenClick = () => {
    setShowElements(false);
  };



  return (
    <div className="App" onClick={handleScreenClick}>
      <Header 
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />

      {!showElements && <MultiStepForm darkMode={darkMode} />}
      
      <DynamicBackground showElements={showElements} darkMode={darkMode} />
      {!darkMode && <StarrySky />}
      
  
    </div>
  );
}

export default App;
