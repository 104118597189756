import React, { useState, useEffect } from 'react';
import MantraTheme from './MantraTheme';
import ConfirmAudioSettings from './ConfirmAudioSettings';
import FinalResult from './FinalResult';

const languageData = [
    { language: 'EN', languageCode: 'en-US'},
    { language: 'SPN', languageCode: 'es-US'},
    { language: 'FR', languageCode: 'fr-CA'},
  ];

  const MultiStepForm = ({ darkMode, onStepChange }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        mantrasText: '',
        voice: 'Male',
        music: 'Music 1'
    });


    const updateFormContainerStyle = (darkMode) => {
        const formContainer = document.querySelector('.form-container');
        if (formContainer) {
            formContainer.style.backgroundColor = darkMode ? 'darkblue' : '#e08282';
        }
    };

    const updateAudioPlayerStyle = (darkMode) => {
        const audioPlayers = document.querySelectorAll('.audio-player');
        audioPlayers.forEach(audioPlayer => {
            audioPlayer.style.backgroundColor = darkMode ? 'rgb(0, 0, 104)' : '#814141';
        });
    };

    useEffect(() => {
        updateFormContainerStyle(darkMode);
        updateAudioPlayerStyle(darkMode);
    }, [darkMode, step]);

    
    const [inputFields, setInputFields] = useState([{ value: '' }]);

    const handleInputChange = (index, event) => {
        const value = event.target.value; // Extracting the string value from the event object
        const newInputFields = [...inputFields];
        newInputFields[index].value = value;
        setInputFields(newInputFields);
      };
    

    const [selectedLanguage, setSelectedLanguage] = useState('EN');

    const [loading, setLoading] = useState(false);

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
      };
    
      
    
    // Proceed to next step
    const nextStep = () => {
        setLoading(true);
        // Simulate a delay, replace this with your condition or API call
        setTimeout(() => {
            setLoading(false);
            setStep(prevStep => prevStep + 1);
        }, 1000);
    };

    // Go back to prev step
    const prevStep = () => {
        setLoading(true);
        // Simulate a delay, replace this with your condition or API call
        setTimeout(() => {
            setLoading(false);
         
         
            setStep(prevStep => prevStep - 1);
        }, 1000);
    };



  

    // Reset the form
    // Reset the form
    const resetForm = () => {
      setStep(1);
      setFormData({
          name: '',
          mantrasText: '',
          voice: 'Male',
          music: 'Music 1'
      });
      setAudioUrl('');
      setLoading(false);  // Also reset the loading state
  };

  const handleNextStep = (callback) => {
    setLoading(true);
    Promise.resolve(callback())
        .then(newAudioUrl => {
            if (newAudioUrl) {
                storeAudioUrl(newAudioUrl);  // Store the new audio URL if it's provided
            }
            setStep(prevStep => prevStep + 1);
        })
        .catch(error => {
            console.error('There was an error:', error);
        })
        .finally(() => {
            setLoading(false);
        });

};

const getLanguageName = (language) => {
    const languageInfo = languageData.find(lang => lang.language === language);
    return languageInfo ? languageInfo.languageName : '';
};

    const [audioUrl, setAudioUrl] = useState('');
    
    const storeAudioUrl = (url) => {
        setAudioUrl(url);
    };

    // Handle fields change
    const handleChange = input => value => {
        if (value.target) {
            setFormData({ ...formData, [input]: value.target.value });
        } else {
            setFormData({ ...formData, [input]: value });
        }
    };

    
    const { mantrasText, theme } = formData;

    if (loading) {
        return null;
    }

    switch (step) {
        case 1:
            return         <MantraTheme
            handleNextStep={handleNextStep}  // Assuming doMantraThemeStep is defined
            handleChange={handleChange}
            values={{ theme }}
            setLoading={setLoading}
            nextStep={nextStep}
            selectedLanguage={selectedLanguage}  // Pass selectedLanguage as a prop
            onLanguageChange={handleLanguageChange}  // Pass handleLanguageChange as a prop
            inputFields={inputFields}
            setInputFields={setInputFields}
            handleInputChange={handleInputChange}
            darkMode={darkMode}
          />
        
        // case 2:
        //     return <ConfirmText 
        //                 prevStep={prevStep} 
        //                 nextStep={nextStep} 
        //                 handleChange={handleChange} 
        //                 values={{ mantrasText: mantrasText }}
        //                 selectedLanguage={selectedLanguage} 
                        
        //             />;
        case 2:
            return         <ConfirmAudioSettings
            prevStep={prevStep}
            nextStep={nextStep}
            handleNextStep={handleNextStep}   // Pass handleNextStep down to ConfirmAudioSettings
            handleChange={handleChange}
            values={formData}
            mantras={mantrasText}
            storeAudioUrl={storeAudioUrl}
            setLoading={setLoading}
            selectedLanguage={selectedLanguage}
            onLanguageChange={handleLanguageChange}
            languageName={getLanguageName(selectedLanguage)}  // Assuming you have a function getLanguageName
            gender={formData.voice} 
        />;

        case 3: 
        return (
            <FinalResult
                resetForm={resetForm}
                newAudioUrl={audioUrl}
                selectedLanguage={selectedLanguage}
                inputFields={inputFields}
            />
        );
    default:
        return <div>Invalid step</div>;
    }
};

export default MultiStepForm;
