import React from 'react';
import './Header.css';

const Header = ({ darkMode, setDarkMode }) => {

    const handleHeaderClick = (e) => {
        e.stopPropagation(); // This stops the click event from bubbling up to the parent
    };

    const handleThemeSwitchChange = (e) => {
        setDarkMode(e.target.checked); // Update darkMode based on checkbox status
    };

    // Define the background color based on darkMode
    const headerClassName = `header-wrapper ${darkMode ? 'dark-mode' : ''}`;

    return (
        <header className={headerClassName} onClick={handleHeaderClick}>
            <h1 className="title">myaffirmations.net</h1>
            <div className="theme-switch-wrapper">
                <label className="theme-switch" htmlFor="checkbox">
                    <input type="checkbox" id="checkbox" onChange={handleThemeSwitchChange} checked={darkMode} />
                    <div className="slider round"></div>
                </label>
            </div>
        </header>
    );
};

export default Header;
