import React from 'react';
import './ShootingStars.css'; // Assuming you've saved your CSS in this file

const ShootingStars = () => {
  return (
    <div>
      <div className="sky-container">
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
      </div>
      <div className="border"></div>
    </div>
  );
};

export default ShootingStars;
